import axios from 'axios';
import csrfHeader from 'lib/csrf-header';

const instance = axios.create();

instance.interceptors.request.use(function(config) {
  config.headers = { ...csrfHeader(), ...config.headers };
  return config;
});

export default instance;