'use strict';

export function b64toBlob(data) {
  var byteString = atob(data.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
}

export function degToDmsRational(degFloat) {
  degFloat = Math.abs(degFloat);
  var minFloat = degFloat % 1 * 60
  var secFloat = minFloat % 1 * 60
  var deg = Math.floor(degFloat)
  var min = Math.floor(minFloat)
  var sec = Math.round(secFloat * 100)

  deg = Math.abs(deg) * 1
  min = Math.abs(min) * 1
  sec = Math.abs(sec) * 1

  return [[deg, 1], [min, 1], [sec, 100]]
}