import React, { useRef, useEffect, useState } from 'react';
import useStateRef from 'react-usestateref';
import ReactDOM from 'react-dom';
import { b64toBlob } from 'lib/image'
import axios from 'lib/axios-instance';
import { DirectUpload } from '@rails/activestorage';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import canvasConfetti from 'canvas-confetti';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import LoadingImage from 'images/loading.svg';
import ExploreIcon from 'images/icon_explore.svg';
import AddIcon from 'images/icon_add.svg';
import UploadIcon from 'images/icon_upload.svg';

let map, geocoder;
let sourceLoaded = false;
let pinsBeforeUserPrompt = 2;
let mapboxAccessToken = 'pk.eyJ1IjoibmluYWNlY2lsaWUiLCJhIjoiY2thcXNicW4yMDNmMzJzbzVtcnBjZWNzOSJ9.a3-LyH4VeC8vprevn4BVnw';

const questions = [
  {
    question: 'title'
  },
  {
    question: 'I chose this place because...',
    answers: ['It is a good place to relax', 'It is a good place to meet new people', 'There are different activities to do here',
      'It feels safe', 'Of the good air quality', 'It is a natural stop on the way to where I go', 'It has a fun atmosphere', 'It is convenient',
      'It is close to home', 'It is a place I visit often']
  },{
    question: 'I visit this place...',
    answers: ['By myself', 'With children', 'With friends or family', 'To meet or see new people', 'With pets', 'During the day', 'In the evenings',
      'Mostly at weekends', 'Only on rare occasions', 'By bike', 'By foot', 'By car', 'By public transit']
  }, {
    question: 'This place is attractive to me because...',
    answers: ['There is nature', 'There is a great view', 'There is public art',
      'There is interesting architecture', 'There are interesting and friendly people',
      'It feels well protected from the weather (rain, wind, sun...)',
      'There are places to sit, relax and spend time', 'The area feels welcoming',
      'There are amenities like shops, restaurants and cafes', 'It has safe and inviting areas to play',
      'There are plenty of activity facilities and opportunities for exercise']
  }, {
    question: 'Tell us about how this place welcomes babies and toddlers',
    answers: [
      'I see a lot of babies and toddlers here',
      'I bring my own child or children here',
      'I have friends/family who bring their children here',
      'I never see children in this place',
      'I don\'t know']
  }
];

let filterOptions = [
  {
    'text': 'That are good for relaxing',
    'answer': ['It is a good place to relax']
  },
  {
    'text': 'With a fun atmosphere',
    'answer': ['It has a fun atmosphere']
  },
  {
    'text': 'That welcomes babies and toddlers',
    'answer': ['I see a lot of babies and toddlers here', 'I bring my own child or children here', 'I have friends/family who bring their children here', 'With children']
  },
  {
    'text': 'That feel safe',
    'answer': ['It feels safe', 'It has safe and inviting areas to play']
  },
  {
    'text': 'With Nature',
    'answer': ['There is nature']
  },
  {
    'text': 'Where you can meet new people',
    'answer': ['It is a good place to meet new people']
  },
  {
    'text': 'With interesting architecture',
    'answer': ['There is interesting architecture']
  },
  {
    'text': 'With opportunities to play or do exercise',
    'answer': ['It has safe and inviting areas to play', 'There are plenty of activity facilities and opportunities for exercise']
  }
];

const PinMap = (props) => {
  mapboxgl.accessToken = mapboxAccessToken;

  const mapContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [directUploadUrl, setDirectUploadUrl] = useState(props.url)
  const [currentStyle, setCurrentStyle] = useState('ninacecilie/ckqm8n7he03u318o9pyd9dasj')
  const [persistedData, setPersistedData] = useState({'type': 'FeatureCollection', 'features': []});
  const [images, setImages] = useState([]);
  const [currentUser, setCurrentUser] = useState(props.currentUser)
  const [addingPin, setAddingPin, addingPinRef] = useStateRef(false);
  const [addingPhoto, setAddingPhoto, addingPhotoRef] = useStateRef(false);
  const [newDataPoint, setNewDataPoint, newDataPointRef] = useStateRef({});
  const [pinsAdded, setPinsAdded] = useState([]);
  const [pinPoupData, setPinPopupData] = useState(null);
  const [forceShowSignUp, setForceShowSignUp] = useState(false);
  const [mapAlert, setMapAlert, mapAlertRef] = useStateRef({topic: 'instruction--explore', message: ''});
  const [activeFilter, setActiveFilter] = useState('');
  const [showPulicLifeImages, setShowPublicLifeImages] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const {fromUser} = props;

  // SETUP MAP
  useEffect(() => {    
    map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/' + currentStyle,
      center: [26.41855859259502, 38.127825249990536 ],
      zoom: 1.6610466717663022,
    });

    geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      placeholder: 'Jump to a city',
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true
    });

    if(!fromUser) document.querySelector('#geocoder-container').appendChild(geocoder.onAdd(map));

    // Map controls
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();

    map.on('load', function () {
      addSourcesAndLayers();
      getPins().then((pins) => {
        let data = {
          'type': 'FeatureCollection',
          'features': pins.map((pin) => {
            return {
              geometry: pin.definition.geometry,
              properties: {
                answers: (pin.description === null ? [] : 
                  pin.description.map((d) => {
                    if(d.answers !== undefined) return d.answers;
                  }).flat()
                ),
                photoText: (pin.description === null || pin.description[0].photoText === undefined) ? [] : pin.description[0].photoText,
                createdAt: new Date(pin.created_at),
                id: pin.id,
                isPublicLifePhoto: (pin.is_public_life_photo === null || !pin.is_public_life_photo) ? false : true,
                userId: pin.user_id,
                hasImage: 'image_attachment' in pin,
                locationName: (pin.location_name === '' || pin.location_name === null) ? '' : pin.location_name,
                pinTitle: (pin.title === '' || pin.title === null) ? '' : pin.title
              }
            }
          })
        }

        if(!fromUser) {
          // Shuffle the images and pick 12
          let imgs = pins.filter((p) => p.is_public_life_photo);
          for (let i = imgs.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [imgs[i], imgs[j]] = [imgs[j], imgs[i]];
          }
          let chosenImages = imgs.slice(0, 12);
          chosenImages.forEach((img) => {
            getImage(img.id).then((url) => img.image_url = url);
          });
          setImages(chosenImages);
        }
      
        setTimeout(() => {
          setPersistedData(data);
          setIsLoading(false);
        }, 500);
      });
      
    });

    map.on('sourcedata', (e) => {
      if (e.sourceId === 'newPlaces' && e.isSourceLoaded && !sourceLoaded) {
        sourceLoaded = true;
      }
    });

    geocoder.on('result', (e) => {
      const padding = {
        top: 80, bottom: 80, left: 80, right: 80,
      };
      map.fitBounds(e.result.bbox, { duration: 1000, padding });
      setSidebarOpen(false);
    });

    map.on('click', 'placesLayer', function (e) {
      map.flyTo({
        center: e.features[0].geometry.coordinates
      });
    });

    map.on('mouseenter', 'placesLayer', function () {
      map.getCanvas().style.cursor = 'pointer';
    });
       
      // Change it back to a pointer when it leaves.
    map.on('mouseleave', 'placesLayer', function () {
      map.getCanvas().style.cursor = '';
    });

    map.on('click', (e) => {
      if(mapAlertRef.current.topic.includes('instruction')) setMapAlert({topic: '', message: ''});
      // Not in add mode
      if(!addingPinRef.current && !addingPhotoRef.current) {
        let bbox = [
          [e.point.x - 15, e.point.y - 15],
          [e.point.x + 15, e.point.y + 15]
        ];
        var features = map.queryRenderedFeatures(bbox, {
          layers: ['placesLayer']
        });
        if(features.length > 0) setPinPopupData({pin: features[0], event: e, fromImage: false});
      } else {
        if(!(addingPinRef.current || addingPhotoRef.current) || Object.entries(newDataPointRef.current).length > 0) return;
        if (map.getZoom() < 10) return setMapAlert({ topic: 'zoom', message: "Hmm hard to get precise at this zoom level, isn't it? Try zooming in a bit before you add your " + (addingPhotoRef.current ? 'photo!' : 'pin!')});
        let coordinates = e.lngLat;
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        map.flyTo({
          center: coordinates
        });

        let point = {
          "type": "Feature",
          "properties": {},
          "geometry": {
            "type": "Point",
            "coordinates": [
              coordinates.lng,
              coordinates.lat
            ]
          }
        }

        if (addingPinRef.current || addingPhotoRef.current) setNewDataPoint(point);
      }
    });

    map.on('zoomstart', (e) => {
      if(mapAlertRef.current.topic === 'zoom' || mapAlertRef.current.topic.includes('instruction')) setMapAlert({topic: '', message: ''});
    });
    map.on('movestart', (e) => {
      if(mapAlertRef.current.topic.includes('instruction')) setMapAlert({topic: '', message: ''});
    });

    // Clean up on unmount
    return () => map.remove();

  }, []);

  // If the new data point changes, update the map
  useEffect(() => {
    if(map === undefined || !sourceLoaded) return;
    let newFeatureCollection = {
      "type": "FeatureCollection",
      "features": [
        newDataPoint
      ]
    }
    map.getSource('newPlaces').setData(newFeatureCollection);
  }, [newDataPoint]);

  // If the persisted dataset changes, update the map
  useEffect(() => {
    if(map === undefined || !sourceLoaded) return;
    map.getSource('places').setData(persistedData);
  }, [persistedData]);

  const addSourcesAndLayers = () => {
    map.addSource('places', {
      type: 'geojson',
      data: persistedData
    });

    map.addSource('newPlaces', {
      type: 'geojson',
      data: {
        "type": "FeatureCollection",
        "features": [newDataPoint]
      }
    });

    map.addLayer({
      'id': 'placesLayer',
      'type': 'circle',
      'source': 'places',
      'layout': {},
      'paint': {
        'circle-radius': {
          'base': 6,
          'stops': [
            [12, 6],
            [22, 180]
          ]
        },
        'circle-color': [
          'case',
          ['boolean', ['get', 'isPublicLifePhoto'], true],
          '#51D07A',
          '#1C3E35'
        ],
        'circle-stroke-color': 'white',
        'circle-stroke-width': 1
        }
    });

    map.addLayer({
      'id': 'newPlacesLayer',
      'type': 'circle',
      'source': 'newPlaces',
      'layout': {}
    });
  }

  const getPins = () => {
    let p = props.fromUser ? {userId: props.currentUser.id} : {};
    return new Promise((resolve, reject) => {
      axios.get(`/pins`, {params: p})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          console.log(err);
        });
    })
  }

  const saveNewPin = (dataPoint, dataPointAnswers, pinTitle, image = '', imageUrl = '', isPublicLifePhoto = false) => {
    let data = {
      pin: {
        definition: JSON.stringify(dataPoint),
        description: JSON.stringify(dataPointAnswers),
        user_id: currentUser === null ? null : currentUser.id,
        is_public_life_photo: isPublicLifePhoto,
        location_name: '',
        title: pinTitle
      }
    };

    if(image !== '') data.pin.image = image;

    enableExploreMode();
    setMapAlert({topic: 'pinSaved', message: 'Thank you for pinning one of your favorite places!'});
    setTimeout(() => {
      setMapAlert({topic: '', message: ''});
    }, 5000);
    fireConfetti();

    reverseGeocode(dataPoint).then((location) => {
      data.pin.location_name = location;

      axios.post(`/pins`, data).then(response => {
        setPinsAdded([...pinsAdded, response.data.id]);
  
        let savedDataPoint = dataPoint;
        savedDataPoint.properties = {
          createdAt: new Date(),
          id: response.data.id,
          answers: dataPointAnswers.filter((d) => d.answers !== undefined && d.answers.length > 0).map((d) => d.answers).flat(),
          photoText: dataPointAnswers[0].photoText === undefined ? '' : dataPointAnswers[0].photoText,
          image: [{image: imageUrl}],
          isPublicLifePhoto: isPublicLifePhoto,
          hasImage: image === '' ? false : true,
          locationName: location,
          pinTitle: pinTitle
        }
        setPersistedData({...persistedData, features: [...persistedData.features, savedDataPoint] })
        setNewDataPoint({});
      }).catch((err) => {
        console.log(err);
        setMapAlert({topic: 'pinNotSaved', message: 'Hmmm, something went wrong with trying to save your pin. Please refresh the page and try again.'});
      });

    });
  }

  const reverseGeocode = (pin) => {
    return new Promise((resolve, reject) => {
      let geoCodeURL = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'+ pin.geometry.coordinates[0]+','+ pin.geometry.coordinates[1] +'.json?access_token='+mapboxAccessToken;
      axios.get(geoCodeURL)
        .then(response => {
          if(response.status !== 200) resolve('');

          let location = '';
          response.data.features[0].context.forEach((elem) => {
            if(elem.id.includes('place')) location += elem.text;
            if(elem.id.includes('country')) location += location === '' ? elem.text : (', '+elem.text);
          });
          resolve(location);
        })
        .catch((err) => {
          console.log(err);
          resolve('');
        });
    })
  }

  const fireConfetti = () => {
    var count = 200;
    var defaults = {
      origin: { y: 0.7 },
      colors: ['#42B9D3', '#51D07A', '#1C3E35', '#F3ECDD']
    };

    const firePart = (particleRatio, opts) => {
      canvasConfetti(Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio)
      }));
    }

    firePart(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    firePart(0.2, {
      spread: 60,
    });
    firePart(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    firePart(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    firePart(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  const closePinPopup = () => {
    setPinPopupData(null);
  }

  const deletePin = (pinId, pinUserId) => {
    if(currentUser === null || currentUser.id !== pinUserId) {
      setMapAlert({topic: 'unauthorized', message: 'You don\'t have the authorization to delete this pin'});
      return setTimeout(() => {
        setMapAlert({topic: '', message: ''});
      }, 5000);
    }
    axios.delete('/pins/' + pinId)
      .then(response => {
        if(response.status === 200) setPersistedData({...persistedData, features: persistedData.features.filter((item) => item.properties.id !== pinId)});
        closePinPopup();
        setMapAlert({topic: 'pinDeleted', message: 'Pin deleted successfully'});
        return setTimeout(() => {
          setMapAlert({topic: '', message: ''});
        }, 5000);
      })
      .catch(err => {
        console.log(err.response);
      });
  }

  const updatePinTitle = (pinId, pinTitle) => {
    axios.put('/pins/' + pinId, {pin: {title: pinTitle}})
      .then(response => {
        if(response.status === 200) setPersistedData({...persistedData, features: persistedData.features.map((item) => {
          if (item.properties.id == pinId) {
            item.properties.pinTitle = pinTitle;
            setPinPopupData({...pinPoupData, pin: item});
          };
          return item;
        })});
        setMapAlert({topic: 'pinUpdated', message: 'Pin name was updated!'});
        return setTimeout(() => {
          setMapAlert({topic: '', message: ''});
        }, 5000);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const closeNewPinPopup = () => {
    setNewDataPoint({});
  }
  const handleNewUserCreated = (newUser) => {
    setCurrentUser({id: newUser});
  }
  const selectStyle = (e) => {
    let newStyle = e.target.getAttribute('data-style');
    setCurrentStyle(newStyle);
    map.setStyle('mapbox://styles/' + newStyle);
    setTimeout(() => addSourcesAndLayers(), 500); // All styles are removed 

  }
  const enableExploreMode = () => {
    setAddingPin(false);
    setAddingPhoto(false);
    setNewDataPoint({});
    setMapAlert({topic: 'instruction--explore', message: ''});
  }
  const enableAddMode = () => {
    setAddingPin(true);
    setAddingPhoto(false);
    setNewDataPoint({});
    setMapAlert({topic: 'instruction--add', message: ''});
  }
  const enablePhotoMode = () => {
    setAddingPin(false);
    setAddingPhoto(true);
    setNewDataPoint({});
    setMapAlert({topic: 'instruction--upload', message: ''});
  }
  const handleInstructionsClick = () => setShowInstructions(false);

  const handleShowSignUp = () => {
    setForceShowSignUp(true);
  }
  const handleCloseSignUp = () => {
    setForceShowSignUp(false);
  }
  const handleClickedImage = (feature, e) => {
    setPinPopupData({pin: feature, event: e, fromImage: true});
  }

  // RENDER ALL
  return (
    <>
      <div id="map" className={fromUser ? 'from-user' : 'from-frontpage'} ref={mapContainerRef}>
        <div className="mapboxgl-ctrl mapboxgl-ctrl-group" id="map-style-container">
          <button onClick={selectStyle} className={currentStyle === "ninacecilie/ckqm8n7he03u318o9pyd9dasj" ? "selected" : ''} data-style="ninacecilie/ckqm8n7he03u318o9pyd9dasj">Vector</button>
          <button onClick={selectStyle} className={currentStyle === "mapbox/satellite-streets-v11" ? "selected" : ''} data-style="mapbox/satellite-streets-v11">Satellite</button>
        </div>
    
        {isLoading &&
          <>
            <div id="loading-background"></div>
            <img id="loading-spinner" src={LoadingImage} />
          </>
        }

        <NewPinPopup
          newDataPoint={newDataPoint}
          saveData={saveNewPin}
          closeNewPinPopup={closeNewPinPopup}
          directUploadUrl={directUploadUrl}
          currentUser={currentUser}
          showSignUp={handleShowSignUp}
          addingPhotoRef={addingPhotoRef}
        />
        
        <MapAlert mapAlert={mapAlert}/>

        <PinPopup 
          pinData={pinPoupData}
          closePinPopup={closePinPopup}
          fromUser={fromUser} 
          deletePin={deletePin}
          updatePinTitle={updatePinTitle}
        />

        {!fromUser &&
          <div id="mode-switcher-container" className="btn-group" role="group" aria-label="Mode switcher">
            <button type="button" className={addingPin || addingPhoto ? 'btn btn-outline-primary' : 'btn btn-outline-primary active'} onClick={enableExploreMode}>
              <span className="text-small">Explore</span>
              <span className="text-big">Explore the Map</span>
            </button>
            <button type="button" className={addingPin ? 'btn btn-outline-primary active' : 'btn btn-outline-primary'} onClick={enableAddMode}>
              <span className="text-small">Add</span>
              <span className="text-big">Add your Favorite Places</span>
            </button>
            <button type="button" className={addingPhoto ? 'btn btn-outline-primary active' : 'btn btn-outline-primary'} onClick={enablePhotoMode}>
              <span className="text-small">Upload</span>
              <span className="text-big">Upload images of Public Life</span>
            </button>
          </div>
        }

        {!fromUser && 
          <SignUp
            currentUser={currentUser}
            pinsAdded={pinsAdded}
            setNewUser={handleNewUserCreated}
            forceShow={forceShowSignUp}
            closeForceShow={handleCloseSignUp}
          />
        }
      </div>

      {!fromUser &&
        <Sidebar
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          showPulicLifeImages={showPulicLifeImages}
          setShowPublicLifeImages={setShowPublicLifeImages}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          mapAlertRef={mapAlertRef}
          setMapAlert={setMapAlert}
        />
      }

      {!fromUser &&
        <ImageCarousel images={images} allData={persistedData} handleClickedImage={handleClickedImage} />
      }
    </>
  )
}

const Sidebar = (props) => {
  const {activeFilter, setActiveFilter, showPulicLifeImages, setShowPublicLifeImages, sidebarOpen, setSidebarOpen, mapAlertRef, setMapAlert} = props;

  useEffect(() => {
    if(map === undefined) return;

    let filter = ['any'];
    if(showPulicLifeImages) filter.push(['==', ['get', 'isPublicLifePhoto'], true]);
    
    if(activeFilter.length > 0) {
      let answers = [];
      filterOptions.forEach((option) => {
        if(activeFilter.includes(option.text)) answers.push(option.answer);
      });
      answers.flat().forEach((a) => {
        filter.push(['in', a, ['get', 'answers']])
      });
    } else {
      filter.push(['==', ['get', 'isPublicLifePhoto'], false])
    }
    
    map.setFilter('placesLayer', filter);
  }, [activeFilter, showPulicLifeImages]);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  const handleFilterClick = (e) => {
    if(mapAlertRef.current.topic.includes('instruction')) setMapAlert({topic: '', message: ''});
    let text = e.target.dataset.text;
    if(text === "all") {
      setActiveFilter('');
    } else if(text === "public-life-images") {
      setShowPublicLifeImages(showPulicLifeImages => !showPulicLifeImages);
    } else {
      activeFilter === text ? setActiveFilter('') : setActiveFilter(text);
    }
  }

  return (
    <>
      <div id="sidebar-toggle-container" onClick={toggleSidebar}>
        <div id="sidebar-toggle">Search the Map</div>
      </div>
      <div id="sidebar-container" className={sidebarOpen ? "active" : ""}>
        <div id="close-sidebar-btn" onClick={toggleSidebar}>×</div>
        <div id="sidebar-content">
          <div id="geocoder-container" className="mb-4"></div>
          <div id="filters-container">
            <li key='all pins' data-text="all" onClick={handleFilterClick} className={activeFilter.length === 0 ? "selected" : ""}>Show me all Favorite Places</li>
            <li key='public life images' id="public-life-images" data-text="public-life-images" onClick={handleFilterClick} className={showPulicLifeImages ? "selected" : ""}>Show Images of Public Life</li>
            <h5 className="mt-4">Show me places...</h5>
            <ul id="filter-container" className="list-group list-group-flush">
              {filterOptions.map((option) => {
                return (
                  <li key={option.text} data-answer={option.answer} data-text={option.text} onClick={handleFilterClick} className={activeFilter.includes(option.text) ? "selected" : ""}>
                    {option.text}
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
    
  )
}

const PinPopup = (props) => {
  const {pinData, closePinPopup, fromUser, deletePin, updatePinTitle} = props;
  const popupRef = useRef();

  const [imageUrl, setImageUrl] = useState('');
  let pinTitle = '';
  let coordinates = [];
  let imageText = '';
  let chosenAnswers = [];

  if(pinData !== null) {
    const {pin, event, fromImage} = pinData;

    if(pin.properties.hasImage) getImage(pin.properties.id).then((url) => setImageUrl(url));
  
    coordinates = pin.geometry.coordinates.slice();
    if(!fromImage) {
      while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) coordinates[0] += event.lngLat.lng > coordinates[0] ? 360 : -360;
    } else {
      map.flyTo({
        center: pin.geometry.coordinates,
        zoom: 9,
        speed: 1.5
      });
    }

    if(pin.properties.photoText !== '' && pin.properties.photoText !== '[]' && pin.properties.photoText.length > 0) imageText = pin.properties.photoText;

    // Show 1-2 picks from the answers, if no other thing to show
    if(imageText === '' && pin.properties.answers[0] !== undefined) {
      let answers = JSON.parse(pin.properties.answers);
      let uninterestingAnswers = ['By myself', 'With children', 'With friends or family', 'To meet or see new people', 'With pets', 'During the day', 'In the evenings', 'Mostly at weekends', 'Only on rare occasions', 'By bike', 'By foot', 'By car', 'By public transit', 'Other', 'I never see children in this place', 'I don\'t know'];
      let goodAnswers = answers.filter( function( el ) {
        return !uninterestingAnswers.includes( el );
      } );    
      if(goodAnswers.length === 1) {
        chosenAnswers = [goodAnswers[0]];
      } else if (goodAnswers.length > 2) {
        chosenAnswers = goodAnswers.sort(() => .5 - Math.random()).slice(0, 2);
      }
    }
    
  }
  
  useEffect(() => {
    if(pinData === null) return;

    let classList = pinData.pin.properties.hasImage ? 'has-image' : 'no-image';
    if(fromUser) classList += ' from-user';

    const popup = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      anchor: 'left',
      maxWidth: '500px',
      className: classList
    })
      .setDOMContent(popupRef.current)
      .addTo(map);

    if(coordinates.length > 0) popup.setLngLat(coordinates);
    setImageUrl('');
    pinTitle = pinData.pin.properties.pinTitle;
    return popup.remove;
  }, [pinData]);

  const handleClosePopup = () => {
    setImageUrl('');
    closePinPopup();
  }
  const handlePinTitleChange = (e) => pinTitle = e.target.value;
  const handleUpdatePinTitle = () => {
    let pinId = pinData.pin.properties.id;
    updatePinTitle(pinId, pinTitle);
  }
  const handleDeletePin = () => {
    deletePin(pinData.pin.properties.id, pinData.pin.properties.userId);
  }

  return (
    <div id="pin-details" className={pinData !== null ? '' : 'no-display'} ref={popupRef}>
      <div className="popup-close-btn" onClick={handleClosePopup}>×</div>
      {(pinData !== null && pinData.pin.properties.pinTitle !== '') && (<h3 className="mb-1">{pinData.pin.properties.pinTitle}</h3>)}
      {(pinData !== null && pinData.pin.properties.locationName !== '') && (<p id="location-name" className="small mb-0">{pinData.pin.properties.locationName}</p>)}
      {chosenAnswers.length > 0 && (
        <>
          <p className="small mt-3 mb-1">{chosenAnswers.length === 1 ? "One reason this place was pinned:" : "Two reasons this place was pinned:"}</p>
          <p className="small bullet-before">{chosenAnswers[0]}</p>
          {chosenAnswers.length === 2 && (<p className="small bullet-before">{chosenAnswers[1]}</p>)}
        </>
      )}
      <div id="pin-info-content">
        {imageUrl !== '' && <img src={imageUrl} className="pin-image my-4 img-fluid" />}
        {imageText !== '' && <blockquote className="small blockquote">{imageText}</blockquote>}
        {(fromUser&& pinData !== null) && (
          <>
            <div className="form-group my-4">
              <label htmlFor="pin-title">Update Pin name</label>
              <textarea id="pin-title" name="pin-title" placeholder={pinData.pin.properties.pinTitle} onChange={handlePinTitleChange} className="form-control" rows="1"/>
              <button className="btn btn-sm btn-secondary mt-2" onClick={handleUpdatePinTitle}>Update</button>
            </div>
            <button className="btn btn-sm btn-outline-danger" onClick={handleDeletePin}>Delete this pin</button>
          </>
        )}
      </div>
    </div>
  )
}

const NewPinPopup = ({newDataPoint, saveData, closeNewPinPopup, directUploadUrl, currentUser, showSignUp, addingPhotoRef}) => {
  const popupRef = useRef();

  const [pinTitle, setPinTitle] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionAnswers, setQuestionAnswers] = useState(Array.from(Array(questions.length), () => []));
  const [textareaValue, setTextareaValue] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    cleanup();
  }, [newDataPoint])

  useEffect(() => {
    if(Object.entries(newDataPoint).length <= 0) return;
    const popup = new mapboxgl.Popup({
      closeOnClick: false, 
      closeButton: false,
      anchor: 'left',
      maxWidth: '500px',
      className: 'new-pin'
    })
      .setLngLat(newDataPoint.geometry.coordinates)
      .setDOMContent(popupRef.current)
      .addTo(map);

    return popup.remove;
  }, [currentQuestion, newDataPoint]);

  const cleanup = () => {
    setPinTitle('');
    setCurrentQuestion(0);
    setQuestionAnswers(Array.from(Array(questions.length), () => []));
    setTextareaValue('');
    setSelectedFile(null);
  }

  const handleClosePopup = () => {
    cleanup();
    closeNewPinPopup();
  }

  const handleClickPrevious = () => setCurrentQuestion(currentQuestion-1);
  const handleClickNext = () => { if(pinTitle !== '') setCurrentQuestion(currentQuestion+1)};

  const handlePinTitleChange = (e) => setPinTitle(e.target.value);

  const handleAnswerClick = (e) => {
    let answer = e.target.dataset.answer;
    let copy = [...questionAnswers];
    let index = copy[currentQuestion].indexOf(answer); 
    index > -1 ? copy[currentQuestion].splice(index, 1) : copy[currentQuestion].push(answer);
    setQuestionAnswers(copy);
  }

  const handleSelectedFile = (newFile, newFileUrl, isPublicLifePhoto) => {
    setSelectedFile({file: newFile, url: newFileUrl, isPublicLifePhoto: isPublicLifePhoto});
  }

  const handleTextareaChange = (e) => setTextareaValue(e.target.value);

  const handleShowSignUp = () => showSignUp();

  const handleSave = () => {
    let dataPointToSave = newDataPoint;
    let dataPointAnswers = {};
    if(addingPhotoRef.current) {
      dataPointAnswers = [{photoText : textareaValue}]
    } else {
      dataPointAnswers = JSON.parse(JSON.stringify(questions));
      dataPointAnswers.forEach((elem, index) => elem.answers = questionAnswers[index]);
    }
    
    if(selectedFile !== null && Object.entries(selectedFile).length > 0 ) {
      const uploader = new DirectUpload(selectedFile.file, directUploadUrl, this);
      uploader.create((mapAlert, blob) => {
        if (blob) {
          saveData(dataPointToSave, dataPointAnswers, pinTitle, blob.signed_id, selectedFile.url, selectedFile.isPublicLifePhoto);
        }
      });
    } else {
      saveData(dataPointToSave, dataPointAnswers, pinTitle);
    }

    cleanup();
  }

  const Question = () => { 
    return (
      <div className="questions-container">
        <p className="question small">{questions[currentQuestion].question}</p>
        {questions[currentQuestion].answers.map((answer, index) => (
            <div key={index} className={questionAnswers[currentQuestion].includes(answer) ? "question-answer selected" : "question-answer"}>
              <div className="answer-bubble" data-answer={answer} onClick={handleAnswerClick}></div>
              <div className="answer-text" data-answer={answer} onClick={handleAnswerClick}>{answer}</div>
            </div>
        ))}
      </div>
    )
  }

  return (
    <div id="new-pin-details" className={Object.entries(newDataPoint).length > 0 ? '' : 'no-display'} ref={popupRef}>
      <div className="popup-close-btn" onClick={handleClosePopup}>×</div>
      <h3>{addingPhotoRef.current ? 'Upload a photo of a great public life moment' : 'Add a new favorite place'}</h3>

      {(currentQuestion !== 0 && currentQuestion < questions.length) && <Question/>}

      {(currentQuestion >= questions.length || addingPhotoRef.current) &&
        <ImageUploader
          currentUser={currentUser}
          handleSelectedFile={handleSelectedFile}
          showSignUp={handleShowSignUp}
          addingPhotoRef={addingPhotoRef}
        />
      }

      {currentQuestion === 0 && ((addingPhotoRef.current && currentUser !== null) || !addingPhotoRef.current) &&
        <div className="questions-container">
          {!addingPhotoRef.current && (<p className="small mt-4">Thank you for showing us this place! <br/> We would like to know just a bit more before adding it to the map.</p>)} 
          <p className="small">
            {addingPhotoRef.current ? "Please add a caption to your image and tell us why you want to share it with us." : "Let's start by giving your pin a name. E.g. 'The Ueno Park pond in Tokyo' or 'A great children's playground in Vesterbro, Copenhagen'."}
          </p>
          <div className="form-group">
            <label htmlFor="pin-title">{addingPhotoRef.current ? "Caption your image" : "Name your pin"}</label>
            <textarea id="pin-title" value={pinTitle} className="form-control" rows="1" onChange={handlePinTitleChange} />
          </div>
        </div>
      }

      {addingPhotoRef.current && currentUser !== null &&
        <div className="form-group">
          <label htmlFor="image-description">Tell us about why you uploaded this</label>
          <textarea id="image-description" className="form-control" rows="3" onChange={handleTextareaChange} />
        </div>
      }

      <div id="new-pin-details-navigation" className={addingPhotoRef.current ? "image-mode" : ""} >
        <div className="d-flex justify-content-between align-items-center">
          <p onClick={handleClickPrevious} className={currentQuestion > 0 && !addingPhotoRef.current ? "popup-nav-item" : "no-display"}>← Previous</p>
          <p onClick={handleClickNext} className={currentQuestion < questions.length && !addingPhotoRef.current ? "popup-nav-item" : "no-display"} style={{ marginLeft: 'auto' }}>
            <span className={pinTitle === '' ? 'disabled' : ''}> Next →</span>
          </p>
          <button onClick={handleSave} className={currentQuestion >= questions.length || (addingPhotoRef.current && currentUser !== null) ? "btn btn-outline-primary" : "btn btn-outline-primary no-display"}
                  disabled={selectedFile === null && addingPhotoRef.current} >Save Pin</button>
        </div>
      </div>
    </div> 
  )
}

const ImageUploader = (props) => {
  const {handleSelectedFile, currentUser, showSignUp, addingPhotoRef} = props;
  const [selectedFileUrl, setSelectedFileUrl] = useState('');

  const clickSignUpLink = () => {
    showSignUp();
  }

  const selectFile = (event) => {
    event.preventDefault();

    let file = event.target.files[0];
    let fileExtension = file.name.split('.').pop()

    var reader = new FileReader();
    reader.onload = function (e) {
      let data = e.target.result;
      let newImageBlob = b64toBlob(data);
      let newFile = new File([newImageBlob], (new Date().toISOString() + '.' + fileExtension));
      const objectUrl = URL.createObjectURL(newImageBlob);
      setSelectedFileUrl(objectUrl);
      handleSelectedFile(newFile, objectUrl, addingPhotoRef.current);
    }.bind(this);
    reader.readAsDataURL(file);
  }

  return (
    <>
      <p className="small">{addingPhotoRef.current ? 'Be it a public life activity you were part of, an activity you were observing, or just a relaxing moment that caught your attention. Feel free to add a picture of anything that made you pick up your phone and capture the moment.' : 'Have a great photo of this place? Please share it with us as well!'}</p>
      <input
        type="file"
        id="pin-image"
        name="img"
        accept="image/* .heic"
        onChange={selectFile}
        className="form-control-file shadow-none mb-2"
        disabled={currentUser === null ? "disabled" : ""}
      />

      <img className="img-fluid input-preview my-2" src={selectedFileUrl} alt="" />

      {currentUser === null ? <p className="mt-4 font-italic small">Only registered users can upload images.<br/>
      {addingPhotoRef.current ? <a href="/users/sign_up" className="text-primary cursor-pointer" >Click here to sign up!</a> : <span>After you've saved your pin, you can sign up!</span>} </p> : ""}
    </>
  )
}

const MapAlert = (props) => {
  const {mapAlert} = props;

  let classList = mapAlert.topic.length > 0 ? "popup" : "popup hidden";
  let alertMessage = mapAlert.message;
  let alertIcon = '';

  if(mapAlert.topic.includes('instruction')) {
    classList += " instruction"
    if(mapAlert.topic.includes('explore')) {
      alertIcon = ExploreIcon;
      alertMessage = 'Welcome to the map! You are in <i>Explore Mode</i>. Zoom in, move around the map and click on the pins to see places that people love. Use the Sidebar to find places that matter to you.';
    }
    if(mapAlert.topic.includes('add')) {
      alertIcon = AddIcon;
      alertMessage = 'You are now in the <i>Add mode</i>! Zoom in and click on the map to add your favourite places!';
    }
    if(mapAlert.topic.includes('upload')) {
      alertIcon = UploadIcon;
      alertMessage = 'Do you have a picture of public life that you love? Share it with us by zooming in and clicking on the place you took the photo.'
    }
  }

  return (
    <div id="map-alert-container" className={classList}>
      {alertIcon !== '' && <img className="map-alert-icon" src={alertIcon}></img>}
      <p dangerouslySetInnerHTML={{ __html: alertMessage }} />
    </div>
  )
}

const SignUp = (props) => {
  const {currentUser, pinsAdded, setNewUser, forceShow, closeForceShow} = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [success, setSuccess] = useState(false);
  const [mapAlert, setMapAlert] = useState('');
  const [hasClosedSignUp, setHasClosedSignUp] = useState(false);


  const handleEmailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleClose = () => {
    setHasClosedSignUp(true);
    closeForceShow();
  }
  
  const handleUserSubmit = (e) => {
    e.preventDefault();

    let data = {
      user: {
        email: email,
        password: password
      },
      context: 'map'
    }
    axios.post(`/users`, data)
      .then(response => {
        setMapAlert('');
        setSuccess(true);
        let newUserId = response.data.id;
        assignPinsToNewUser(newUserId);
        setNewUser(newUserId);
      })
      .catch(err => {
        let msg = err.response.data;
        if(msg === 'Password is too short (minimum is 6 characters) and Password is too short (minimum is 6 characters)') msg = 'Password is too short (minimum is 6 characters)';
        setMapAlert(msg);
      });
  }

  const assignPinsToNewUser = (userId) => {
    pinsAdded.forEach((pinId) => {
      axios.put('/pins/' + pinId, {pin: {user_id: userId}})
        .then(response => {
        })
        .catch(err => {
          console.log(err.response);
        });
    });
  }
  
  return (
    <div className={(forceShow || (currentUser === null && pinsAdded.length >= pinsBeforeUserPrompt && !hasClosedSignUp)) ? "popup" : "popup hidden"} id="sign-up-container">
      <h4>Thank you for adding places you love.</h4>

      <p className="small">Sign up to join the celebration! Access features like uploading photos and nominating public life champions. As a community member you can view the open source data, receive updates about events and hear stories about public life in cities around the world. <br/>
      Together we can make life in cities more visible.</p>

      <form className={success ? 'no-display' : ''} onSubmit={handleUserSubmit}>
        <div className="form-group">
          <input placeholder="email@example.com" name="email" type="email" className="form-control" onChange={handleEmailChange} />
          <input placeholder="password" name="password" type="password" className="form-control" onChange={handlePasswordChange} /> 
          <input className="btn btn-secondary text-center" type="submit" value="Join the network" />
        </div>
      </form>

      <p className={success ? 'small' : 'small no-display'}>Great! Check your e-mail.</p>
      <p className="small">{mapAlert}</p>
      <div className="btn btn-sm btn-outline-primary text-center" onClick={handleClose}>No thanks, keep exploring</div>
    </div>
  )
}

const getImage = (pinId) => {
  return new Promise((resolve, reject) => {
    axios.get(`/pins/image`, {params: {pinId: pinId}})
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        console.log(err);
      });
  })
}


const ImageCarousel = (props) => {
  const {images, allData, handleClickedImage} = props;

  const handleClickImage = (event) => {
    let clickedPinId = event.target.dataset.id;

    let feature = allData.features.filter((f) => f.properties.id === clickedPinId);
    handleClickedImage(feature[0], event);
  }

  return (
    <div id="image-carousel-container" className="d-flex justify-content-between align-items-center">
      {images.slice(0, 10).map((image) => {
        let url = image.image_url;
        
        return (
          <img key={image.id} data-id={image.id} className="image-carousel-item img-fluid" src={url} onClick={handleClickImage} />
        )
      })}
    </div>
  )
}

document.addEventListener('DOMContentLoaded', () => {
  let container = document.getElementById('pin-map-container');
  ReactDOM.render(
    <PinMap name="React" url={container.dataset.url} currentUser={JSON.parse(container.dataset.currentUser)} fromUser={JSON.parse(container.dataset.fromUser) || false} />,
    container.appendChild(document.createElement('div')),
  )
});